import React from "react";
import Layout from "../../components/layout";
import CapabilitiesHeroSection from "../../components/capabilitiesherosection";
import AuditButtons from "../../components/auditbuttons";
import SingleButton from "../../components/singlebutton";
import { Helmet } from "react-helmet";
import Audits from "../../components/audits";
import Arrow from "../../components/arrow";

import croaudits from "../../images/digital-audits.png";
import abmultivariatetesting from "../../images/ab-multivariate-testing.png";
import ecommercecro from "../../images/ecommerce-cro.png";
import analyticsforcro from "../../images/analytics-for-cro.png";
import crotraining from "../../images/social-media-training.png";

import capabilityicon from "../../images/capability-digital-strategy-2.png";

import rowtwoheroleft from "../../images/cro-row-two-hero-left.jpg";
import rowtwoheroright from "../../images/cro-row-two-hero-right.jpg";

import rowsixhero1mobile from "../../images/cro-row-six-hero-1-mobile.png";
import rowsixhero2mobile from "../../images/cro-row-six-hero-2-mobile.png";
import rowsixhero3mobile from "../../images/cro-row-six-hero-3-mobile.png";
import rowsixhero4mobile from "../../images/cro-row-six-hero-4-mobile.png";
import rowsixhero5mobile from "../../images/cro-row-six-hero-5-mobile.png";

import rowsixhero1desktop from "../../images/cro-row-six-hero-1-desktop.png";
import rowsixhero2desktop from "../../images/cro-row-six-hero-2-desktop.png";
import rowsixhero3desktop from "../../images/cro-row-six-hero-3-desktop.png";
import rowsixhero4desktop from "../../images/cro-row-six-hero-4-desktop.png";
import rowsixhero5desktop from "../../images/cro-row-six-hero-5-desktop.png";

import rowtenhero1 from "../../images/cro-row-ten-hero-1.png";
import rowtenhero2 from "../../images/cro-row-ten-hero-2.png";
import rowtenhero3 from "../../images/cro-row-ten-hero-3.png";
import rowtenhero4 from "../../images/cro-row-ten-hero-4.png";
import rowtenhero5 from "../../images/cro-row-ten-hero-5.png";

import "../../scss/capabilitypage.scss";

const cro = ({ data }) => {
const metadescription = "Next&Co's conversion rate optimisation services provide continuously improving digital experiences accountable to performance visit our site or call 1300 191 950";
const metatitle = "Conversion Rate Optimisation Agency Melbourne, CRO Company- Next&Co";
const metaurl = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <>
      <Helmet>
        <title>{metatitle}</title>
        <meta name="description" content={metadescription}/>
        <meta name="title" content={metatitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaurl} />
        <meta property="og:title" content={metatitle} />
        <meta property="og:description" content={metadescription} />
        <meta property="og:image" content="facebook.png" />
        <meta property="twitter:url" content={metaurl} />
        <meta property="twitter:title" content={metatitle} />
        <meta property="twitter:description" content={metadescription} />
        <meta property="twitter:image" content="twitter.png" />
      </Helmet>
      <Arrow/>
      <div className="capability-page-general cro-page">
        <Layout>
          <CapabilitiesHeroSection
            heading="Conversion Rate Optimisation"
            description="Conversion Rate Optimisation – Continuously improving digital experiences"
            icon={capabilityicon}
            imagedesktop={data.desktop.childImageSharp.fluid}
            imagemobile={data.mobile.childImageSharp.fluid}
          />
          <section id="row-three">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container row">
                  <div className="col-one">
                    <h4 className="col-onef-heading">What we do</h4>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={croaudits}/>
                        </span>
                        <div className="content">
                          <h3>CRO Audits</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={abmultivariatetesting} />
                        </span>
                        <div className="content">
                          <h3>A/B &amp; Multivariate Testing</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={ecommercecro} />
                        </span>
                        <div className="content">
                          <h3>eCommerce CRO</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-one">
                    <div className="list-items">
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={analyticsforcro} />
                        </span>
                        <div className="content">
                          <h3>Analytics for CRO</h3>
                        </div>
                      </div>
                      <div className="list-item">
                        <span className="icon">
                          <img alt="" src={crotraining} />
                        </span>
                        <div className="content">
                          <h3>CRO Training</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <AuditButtons
            title1="Let’s talk about your CRO."
            title2="Assess your current performance."
            linktitle1="Get in touch"
            link1="#"
            linktitle2="CRO audit"
            link2="/conversion-rate-optimisation-audit/"
          />
          <section id="row-two">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>The power of consistent improvement</h4>
                  <p>Next&amp;Co’s conversion rate optimisation process is designed to systematically improve your website (or other digital assets) conversion rate.  Through a process of experimentation and testing, we design, test and modify certain elements of your website to increase the possibility that a visitor converts or engages with your experience in a meaningful way.</p>
                </div>
                <div className="row">
                  <div className="col">
                    <img alt="" src={rowtwoheroleft} />
                  </div>
                  <div className="col">
                    <img alt="" src={rowtwoheroright} />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-six">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <h4>Our CRO framework</h4>
                  <div className="row mobile-only">
                    <div className="col">
                      <img alt="" src={rowsixhero1mobile} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero2mobile} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero3mobile} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero4mobile} />
                    </div>
                    <div className="col">
                      <img alt="" src={rowsixhero5mobile} />
                    </div>
                  </div>
                  <div className="row desktop-only">
                    <div className="col first">
                      <img alt="" src={rowsixhero1desktop} />
                    </div>
                    <div className="col second">
                      <img alt="" src={rowsixhero2desktop} />
                    </div>
                    <div className="col third">
                      <img alt="" src={rowsixhero3desktop} />
                    </div>
                    <div className="col fourth">
                      <img alt="" src={rowsixhero5desktop} />
                    </div>
                    <div className="col fifth">
                      <img alt="" src={rowsixhero4desktop} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-ten">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="small-inner-container">
                    <div className="col">
                      <h4>We can optimise towards</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img alt="" src={rowtenhero1} />
                      <h5>Increased sales</h5>
                    </div>
                    <div className="col">
                      <img alt="" src={rowtenhero2} />
                      <h5>Increased leads</h5>
                    </div>
                    <div className="col">
                      <img alt="" src={rowtenhero3} />
                      <h5>Increased downloads</h5>
                    </div>
                    <div className="col">
                      <img alt="" src={rowtenhero4} />
                      <h5>Increased engagement</h5>
                    </div>
                    <div className="col">
                      <img alt="" src={rowtenhero5} />
                      <h5>Increased advocacy</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="row-four">
            <div className="outer-container">
              <div className="container-max-width">
                <div className="inner-container">
                  <div className="col-one">
                    <h3>Many of Australia's leading businesses partner with us, you should too.</h3>
                    <SingleButton buttonText="Get in touch"/>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Audits />
        </Layout>
      </div>
    </>
  );
};

export default cro;

export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "capabilities-desktop-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
    mobile: file(relativePath: { eq: "capabilities-mobile-hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
